.nav-tabs {
  border-top: 1px solid $nav-tabs-border-top-color;
  border-bottom: 2px solid $nav-tabs-border-color;
  .nav-item{
    margin-bottom: -2px;
    color: $nav-tabs-link-color;
    .nav-link:hover, .nav-link:focus, .nav-link.active, .nav-link:active {
      background-color: $nav-tabs-active-link-hover-bg;
      color: $nav-tabs-active-link-hover-color;
      border: none;
    }
  }
  .nav-link{
    border: none;
  }
}

.nav-link{
  padding: 5px 15px;
}

.nav-link-vertical{
  padding: 5px 10px;
}

.nav-item {
  cursor: pointer;
}

.hr-tab-nav{
  background-color: $color-hr-grey-light;
  border: none;

  .nav-item{
    margin-bottom: -2px;
    color: $color-hr-anthracite;
    .nav-link{
      color: $color-hr-anthracite;
      &.active{
        border-bottom: 2px solid $color-hr-blue;
        color: $color-hr-blue;
        font-weight: bold;
      }
      &.disabled{
        color: $color-hr-grey-blue;
        cursor: default;
      }
      &:not(.disabled):hover, &:not(.disabled):focus{
        background-color: $color-hr-grey-medium;
        color: $color-hr-anthracite;
      }
    }
  }
}

.hr-vertical-tabs{
  flex-direction: column !important;
  background-color: $color-hr-grey-light;
  border: none;

  .nav-item{
    color: $color-hr-anthracite;
    .nav-link{
      color: $color-hr-anthracite;
      padding: 5px 10px;
      &.active{
        border-bottom: 2px solid $color-hr-blue;
        color: $color-hr-blue;
      }
      &:hover, &:focus{
        background-color: $color-hr-grey-medium;
        color: $color-hr-anthracite;
      }
    }
  }
}
