@mixin hre-button-variant($color,  $hover-color,  $border-color) {
  @include box-shadow(none !important);

  color: $color;
  background-color: $btn-default-bg;
  border-color: $border-color;

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: $btn-bg-disabled !important;
    border-color: $btn-border-disabled !important;
    color: $btn-text-disabled !important;
    opacity: 1;
  }

  &:not([disabled]):not(.disabled):hover,
  //&:not([disabled]):not(.disabled):focus,
  //&:not([disabled]):not(.disabled).focus,
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  &.btn-ext
  {
    background: $border-color;
    border-color: $border-color;
    color: $hover-color;
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: $color;
    border-color: $color;
    color: $btn-default-bg;
    box-shadow: none;
  }

  //&:active,
  //&.active,
  //.open > &.dropdown-toggle {
  //  &:hover,
  //  &:focus,
  //  &.focus {
  //    background: $color;
  //    border-color: $color;
  //    color: $btn-default-bg;
  //  }
  //}
}

.btn {
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background-color: $btn-bg-disabled !important;
    border-color: $btn-border-disabled !important;
    color: $btn-text-disabled !important;
    opacity: 1;
  }
  &.focus, &:focus{
    box-shadow:  none;
  }
}

.btn,
.btn-secondary,
.btn-blue{
  @include hre-button-variant($color-hr-blue, $btn-default-bg, $color-hr-blue);
}

.btn-primary,
.btn-anthracite {
  @include hre-button-variant($color-hr-anthracite, $btn-default-bg, $color-hr-anthracite);
}

.btn-success {
  @include hre-button-variant($success, $btn-default-bg, $success);
}

.btn-info {
  @include hre-button-variant($info, $btn-default-bg, $info);
}

.btn-warning {
  @include hre-button-variant($warning, $btn-default-bg, $warning);
}

.btn-danger {
  @include hre-button-variant($danger, $btn-default-bg, $danger);
}

.btn-link {
  @include hre-button-variant($link-color, $link-color, $btn-default-bg);
  // Make it look like a text link
  @extend a.link;
  // Add additional padding to the left
  border-left: 8px solid transparent;
}

.btn-xs {
  @include button-size($input-padding-y-xs, $input-padding-x-xs, $font-size-sm, $btn-line-height-sm, $border-radius-xs);
}

// "If particular attention needs to be called to the function (e.g. ahead of very long texts or in the form of a prompt) but there is no image and no combination of headline and text area, the button may be in solid HR blue, HR grey or HR cyan with white lettering. In this function no feedback is given when the button is moused over."

.btn-ext {

  &.btn-cyan {
    @include hre-button-variant($color-hr-cyan, $btn-default-bg, $color-hr-cyan);
  }

  &.btn-grey,
  &.btn-gray {
    @include hre-button-variant($color-hr-grey, $btn-default-bg, $color-hr-grey);
  }

  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%3E%3Cpolygon%20points%3D%224.695%2C0.766%2C3.99%2C1.471%2C7.49%2C4.971%2C3.989%2C8.471%2C4.696%2C9.176%2C8.9%2C4.97%22%20style%3D%22fill%3A' + 'rgb(' + red(#fff) + '%2C' + green(#fff) + '%2C' + blue(#fff) + ')' + '%3B%22%20%2F%3E%3C%2Fsvg%3E') !important;
  background-repeat: no-repeat !important;
  background-position: right 10px center !important;
  background-size: 16px 16px !important;
  padding-right: 34px;
  &.btn-xs {
    background-position: right 6px center !important;
    background-size: 14px 14px !important;
    padding-right: 26px;
  }
  &.btn-sm {
    background-position: right 7px center !important;
    background-size: 14px 14px !important;
    padding-right: 30px;
  }
  &.btn-lg {
    background-position: right 10px center !important;
    background-size: 22px 22px !important;
    padding-right: 40px;
  }
}