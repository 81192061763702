//
// Dropdown menus
// --------------------------------------------------

.dropdown-toggle::after {
  display: none;
}

// Dropdown arrow/caret
.caret {
  display: inline-block;
  margin-top: -5px;
  border-style: solid;
  border-width: 0 1px 1px 0;
  transform : rotate(45deg);
}

// The dropdown menu (ul)
.dropdown-menu {
  padding: 0;
  @include box-shadow(none);
  border-top: 1px solid $color-hr-blue;

  > .dropdown-item {
    border-top: 1px solid $dropdown-link-border-color;
    &:first-child {
      border-top: none;
    }
  }

  // Dividers (basically an hr) within the dropdown
  .dropdown-divider {
    margin: 0;
    height: 2px;
    overflow: hidden;
    background-color: inherit;
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    background-color: $color-hr-blue;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    background-color: #fff;
    color: $color-hr-anthracite;
  }
}

// Menu positioning
.dropdown-menu-right {
  margin-right: -1px;
}
.dropdown-menu-left {
  margin-left: -1px;
}

// Component alignment
@include media-breakpoint-up(md) {
  .navbar-right {
    .dropdown-menu {
      margin-right: -1px;
    }
    .dropdown-menu-left {
      margin-left: -1px;
    }
  }
}
