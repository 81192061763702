body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.application-name h2 {
  font-size:2em;
  color: #005192;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Embed-container {
  height: 700px;
  width: 100%;
}

#myProgress {
  width: 100%;
  background-color: #d6d6d6;
  vertical-align: middle;
  text-align: center;
}

#myBar {
  width: 1%;
  height: 30px;
  background-color: rgb(0, 200, 0);
  vertical-align: middle;
  text-align: center;
}
