// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;

$primary:       $color-hr-blue !default;
//$secondary:     $gray-600 !default;
$success:       $color-hr-success !default;
$info:          $color-hr-grey-blue !default;
$warning:       $color-hr-warning !default;
$danger:        $color-hr-danger !default;

//$hr-icon-font-path:           "../../dist/fonts" !default;
$hr-icon-font-path:           "../fonts" !default;
$fa-font-path:                $hr-icon-font-path;

// Body
//
// Settings for the `<body>` element.
$body-color: $color-hr-anthracite !default;

// Links
//
// Style anchor elements.

$link-color:            $color-hr-grey !default;
$link-hover-color:      $link-color !default;
$link-hover-decoration: underline !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  Verdana, Helvetica, Arial, sans-serif !default;
$font-family-serif:       $font-family-sans-serif !default;

$font-size-base:          0.8125rem !default; // Assumes the browser default, typically `16px`

// 768 -
$h1-font-size:            ($font-size-base * 1.69) !default; // 22px
// 768 - 1024
$h1-font-size-screen-sm:  ($font-size-base * 1.85) !default; // 24px
// 1024 +
$h1-font-size-screen-lg:  ($font-size-base * 2.4) !default; // 31px

// 768 -
$h2-font-size:            ($font-size-base * 1.4) !default; // 16px
// 768 - 1024
$h2-font-size-screen-sm:  ($font-size-base * 1.23) !default; // 18px
// 1024 +
$h2-font-size-screen-lg:  ($font-size-base * 1.4) !default; // 16px

// 768 -
$h3-font-size:            ($font-size-base * 1.23) !default; // 16px
// 768 - 1024
$h3-font-size-screen-sm:  ($font-size-base * 1.0) !default; // 13px
// 1024 +
$h3-font-size-screen-lg:  ($font-size-base * 1.23) !default; // 16px

$h4-font-size:            $h3-font-size;
$h4-font-size-screen-sm:  $h3-font-size-screen-sm;
$h4-font-size-screen-lg:  $h3-font-size-screen-lg;

$h5-font-size:            $h3-font-size;
$h5-font-size-screen-sm:  $h3-font-size-screen-sm;
$h5-font-size-screen-lg:  $h3-font-size-screen-lg;

$h6-font-size:            $h3-font-size;
$h6-font-size-screen-sm:  $h3-font-size-screen-sm;
$h6-font-size-screen-lg:  $h3-font-size-screen-lg;

// Components
//
// Define common padding and border radius sizes and more.
//$line-height-base:        1.6 !default; // 20/13

$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;
$border-radius-xs:       0 !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-default-bg:                 #fff !default;

$btn-link-disabled-color:        $color-hr-disabled-text !default;

$btn-text-disabled:              $color-hr-disabled-text !default;
$btn-bg-disabled:                #FFFFFF !default;
$btn-border-disabled:            $color-hr-disabled-border !default;

//== Close
//
//##

$close-font-weight:              normal !default;
$close-color:                    #000 !default;
$close-text-shadow:              none !default;

//== Forms
//
//##

$input-color:                    $body-color !default;
$input-border-color:             $color-hr-grey-blue !default;
$input-border-focus:             $color-hr-grey-blue !default;

$input-text-disabled:            $color-hr-disabled-text !default;
$input-bg-disabled:              #FFFFFF !default;
$input-border-disabled:          $color-hr-disabled-border !default;

$input-group-addon-bg:           #FFF !default;
$input-padding-y-xs:             0.125rem;
$input-padding-x-xs:             0.25rem;

$custom-control-indicator-size-sm: 0.75rem;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

//$state-success-text:             $color-hr-anthracite !default;
$state-success-bg:               $color-hr-success !default;
$state-success-border:           $color-hr-grey-blue !default;

//$state-info-text:                $color-hr-anthracite !default;
$state-info-bg:                  $color-hr-grey-blue !default;
$state-info-border:              $color-hr-grey-blue !default;

//$state-warning-text:             $color-hr-anthracite !default;
$state-warning-bg:               $color-hr-warning !default;
$state-warning-border:           $color-hr-grey-blue !default;

//$state-danger-text:              $color-hr-anthracite !default;
$state-danger-bg:                $color-hr-danger !default;
$state-danger-border:            $color-hr-grey-blue !default;


$card-default-text:          #fff !default;
$card-border-color:        $color-hr-cyan !default;
$card-cap-bg:    $color-hr-cyan !default;

//== Tables
$table-cell-padding:            8px !default;
$table-cell-padding-sm:         5px !default;

$table-accent-bg:               $color-hr-grey-light !default;

$table-hover-bg:                $color-hr-grey-light !default;
$table-active-bg:               $color-hr-grey-light !default;
$table-active-hover-bg:         $color-hr-grey-medium !default;
$table-border-color:            $color-hr-grey-blue !default;


//== Dropdowns
//$dropdown-border:                 $color-hr-grey-blue !default;
//$dropdown-fallback-border:        #b3b3b3 !default;
//$dropdown-divider-bg:             $color-hr-grey-blue !default;
//
$dropdown-link-color:             $body-color !default;
$dropdown-link-border-color:      #fff !default;
$dropdown-link-hover-color:       #fff !default;
$dropdown-link-hover-bg:          $color-hr-blue !default;

//== Navbar
//
//##

// Basics of a navbar
//$navbar-height:                    30px !default;

// Navbar links
$navbar-light-color:                #fff !default;
$navbar-light-hover-color:          $color-hr-anthracite !default;
//$navbar-default-link-hover-bg:             #fff !default;
$navbar-light-active-color:         $color-hr-anthracite !default;
//$navbar-default-link-active-bg:            #fff !default;
$navbar-light-disabled-color:       $color-hr-disabled-text !default;
//$navbar-default-link-disabled-bg:          $color-hr-blue !default;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color:                      $color-hr-blue !default;
$navbar-inverse-bg:                         #fff !default;
$navbar-inverse-border:                     $color-hr-blue !default;

// Inverted navbar links
$navbar-inverse-color:                 $color-hr-anthracite !default;
$navbar-inverse-hover-color:           #fff !default;
$navbar-inverse-hover-bg:              $color-hr-blue !default;
$navbar-inverse-active-color:          #fff !default;
$navbar-inverse-active-bg:             $color-hr-blue !default;
$navbar-inverse-disabled-color:        $color-hr-disabled-text !default;
$navbar-inverse-disabled-bg:           #fff !default;

// Inverted navbar brand label
$navbar-inverse-brand-color:                $navbar-inverse-color !default;
$navbar-inverse-brand-hover-color:          #fff !default;
$navbar-inverse-brand-hover-bg:             transparent !default;

//== Tabs
$nav-tabs-border-color:                     $color-hr-blue !default;
$nav-tabs-border-top-color:                 $color-hr-grey-blue !default;
$nav-tabs-link-color:                       $color-hr-anthracite !default;
//$nav-tabs-link-hover-border-color:          $input-border !default;

$nav-tabs-active-link-hover-bg:             $color-hr-blue !default;
$nav-tabs-active-link-hover-color:          #fff !default;
//$nav-tabs-active-link-hover-border-color:   $color-hr-blue !default;

//$nav-tabs-justified-link-border-color:            $input-border !default;
//$nav-tabs-justified-active-link-border-color:     $input-border  !default;

//** Textual separator for between footer nav elements
$nav-separator:               "|" !default;

//== Navs
//
//##

//=== Shared nav styles
//$nav-link-padding:                          5px 15px !default;

//== Breadcrumbs
//
//##

$breadcrumb-padding-x: 0 !default;
//** Font size is smaller than global font size
$breadcrumb-font-size:          $font-size-base !default;
//** Breadcrumb background color
$breadcrumb-bg:                 transparent !default;
//** Breadcrumb text color
$breadcrumb-color:              $color-hr-grey-blue !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $color-hr-grey-blue !default;
//** Color separator different (Problably by mistake)
$breadcrumb-separator-color:    #cccccc !default;

//== Pagination
//
//##

// Colors are not in the style guide but taken from https://jobs.hannover-rueck.de/index.php?ac=search_result&search_criterion_entry_level=&search_criterion_activity_level=&search_criterion_physical_location=1&search_criterion_keyword=&search_criterion_sap_code_number=&btn_dosearch=24+Treffer+anzeigen
$pagination-color:                     $color-hr-cyan !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    #ddd !default;

$pagination-hover-color:               $pagination-color !default;
$pagination-hover-bg:                  #edebe9 !default;
$pagination-hover-border:              $pagination-border !default;

$pagination-active-color:              $pagination-color !default;
$pagination-active-bg:                 $pagination-hover-bg !default;
$pagination-active-border:             $pagination-border !default;

$pagination-disabled-color:            $color-hr-grey !default;
$pagination-disabled-bg:               $pagination-bg !default;
$pagination-disabled-border:           $pagination-border !default;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding-y:             15px !default;
$alert-border-radius:         $border-radius-base !default;
//$alert-link-font-weight:      normal !default;

//$alert-success-bg:            none !default;
$alert-success-text:          $color-hr-success !default;
//$alert-success-border:        $state-success-border !default;

//$alert-info-bg:               none !default;
$alert-info-text:             $color-hr-grey-blue !default;
//$alert-info-border:           $state-info-border !default;

//$alert-warning-bg:            none !default;
$alert-warning-text:          $color-hr-warning !default;
//$alert-warning-border:        $state-warning-border !default;

//$alert-danger-bg:             none !default;
$alert-danger-text:           $color-hr-danger !default;
//$alert-danger-border:         $state-danger-border !default;


//== Popovers
//
//##

//** Popover body background color
//$popover-bg:                          #fff !default;
//** Popover maximum width
//$popover-max-width:                   276px !default;
//** Popover border color
$popover-border-color:                #ddd !default;
//** Popover fallback border color
$popover-fallback-border-color:       #ddd !default;

//** Popover title background color
//$popover-title-bg:                    $color-hr-grey-light !default;

//** Popover arrow width
$popover-arrow-width:                 10px !default;
//** Popover arrow color
//$popover-arrow-color:                 $popover-bg !default;

//** Popover outer arrow width
//$popover-arrow-outer-width:           ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color:           fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color:  darken($popover-fallback-border-color, 20%) !default;


$hr-border-color:                   $color-hr-grey-blue !default;

//revisit this topic!
$custom-radio-indicator-icon-checked : str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#005192'/%3E%3C/svg%3E"), "#", "%23");