@mixin table-row-variant-hr($state, $color) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      color: $color;
      background-color: transparent;
    }
  }
}

@include table-row-variant-hr('success', $state-success-bg);
@include table-row-variant-hr('info', $state-info-bg);
@include table-row-variant-hr('warning', $state-warning-bg);
@include table-row-variant-hr('danger', $state-danger-bg);

.table {

  border-bottom: 1px solid $table-border-color;

  // Active column
  col {
    &.active {
      background-color: $table-active-bg;
    }
  }

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-right: $table-cell-padding solid $body-bg;

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  > thead > tr > th {
    border-bottom: 1px solid #2f2f2f;
    font-weight: normal;
    &.active {
      background: $color-hr-cyan;
      color: #ffffff;
    }
  }

  .th-sort {
    cursor: pointer;
  }
}


// Hover effect for striped tables
.table-striped {
  &.table-hover {
    > tbody > tr:hover {
      background-color: $table-active-hover-bg;
    }
  }
}
