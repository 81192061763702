.loading-indicator {
  background-color: $color-hr-grey-blue;
  height: 4px;
  width: 100%;
  margin: auto;
  position: relative;
  .loading-bar{
    background-color: $color-hr-blue;
    height: 4px;
    width: 0%;
    animation: progress 3s infinite linear;
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}