.modal-header {
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;

  &.alert-info,
  &.alert-error,
  &.alert-success,
  &.alert-warning {
    .modal-title {
      color: inherit;
    }
  }
}