@mixin bubble-variant($background, $text-color) {
  background-color: $background;
  color: #fff;
  > * {
    color: #fff;
  }
  &:after {
    background-image:
            url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2053.7%2024%22%3E%3Cpolygon%20fill%3D%22' + 'rgb(' + red($background) + '%2C' + green($background) + '%2C' + blue($background) + ')' + '%22%20points%3D%220%2C0%2053.7%2C24%2047.8%2C0%20%22%2F%3E%3C%2Fsvg%3E');
  }
}

.bubble {
  background: #fff;
  position: relative;
  margin: 0 0 42px 0;
  padding: 20px;
  &:after {
    background-size: 72px 32px;
    bottom: -31px;
    content: '';
    display: block;
    height: 32px;
    left: 34px;
    position: absolute;
    width: 72px;
    z-index: 1;
    background-image:
            url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2053.7%2024%22%3E%3Cpolygon%20fill%3D%22' + 'rgb(255%2C255%2C255)' + '%22%20points%3D%220%2C0%2053.7%2C24%2047.8%2C0%20%22%2F%3E%3C%2Fsvg%3E');
  }
}

.bubble-blue {
  @include bubble-variant($color-hr-blue, #fff);
}

.bubble-cyan {
  @include bubble-variant($color-hr-cyan, #fff);
}

.bubble-grey,
.bubble-gray {
  @include bubble-variant($color-hr-grey, #fff);
}
