//
// Popovers
// --------------------------------------------------


.popover {
  padding: 0;
}

.popover-title {
  border-bottom: 1px solid $popover-border-color;
  border-bottom: 1px solid $popover-fallback-border-color;
}
