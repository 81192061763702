.control-group-label {
  font-size: $h4-font-size;
  margin-top: 10px;
}

.form-control {
  box-shadow: none;
  //@include placeholder($color-hr-grey-blue);
  &[disabled],
  &[readonly] {
    color: $input-text-disabled;
  }
  &:focus {
    box-shadow: none;
    border-color: $primary;
  }
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  &.form-control {
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      border-color: $input-border-disabled;
      //color: $input-text-disabled;
      //@include placeholder($input-text-disabled);
      &:hover {
        border-width: 1px;
      }
    }
    &[readonly] {
      color: $color-hr-anthracite;
    }
  }
}

label {
  font-weight: normal;
}

// We render temporal inputs on iOS as regular text input fields
// and reset the line-height to defaults
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    &.form-control,
    &.input-xs,
    .input-group-xs &,
    &.form-control-sm,
    .input-group-sm &,
    &.form-control-lg,
    .input-group-lg & {
      line-height: inherit;
    }
  }
}

// Static form control text
//
// Apply class to a `p` element to make any string of text align with labels in
// a horizontal form layout.

.form-control-plaintext {
  // Size it appropriately next to real form controls
  //padding-top: ($padding-base-vertical + 1);
  //padding-bottom: ($padding-base-vertical + 1);
  // Remove default margin from `p`
  margin-bottom: 0;
  //min-height: ($line-height-computed + $font-size-base);

  &.input-xs {
    padding-left: 0;
    padding-right: 0;
  }
}

// Horizontal forms
//
// Horizontal forms are built on grid classes and allow you to create forms with
// labels on the left and inputs on the right.

.form-horizontal {

  // Form group sizes
  //
  // Quick utility class for applying `.input-xs` styles to the
  // inputs and labels within a `.form-group`.
  .form-group-xs {
    @include media-breakpoint-up(sm) {
      .col-form-label {
        //padding-top: ($padding-small-vertical + 1);
      }
    }
  }
}

@mixin select-background($color-border, $color-arrow) {
  /**
   * Add grafics as background images
   * because "select" elements can't have pseudo content
   *
   * 1. Vertical line
   * 2. Arrow
   */
  /* 2 */
  /* 2 */
  background-color: white;
  background-image: linear-gradient(90deg, white 99px, #a0b4bf 1px), url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2210%22%20height%3D%2210%22%3E%3Cpolygon%20points%3D%225.445%2C7.493%209.148%2C3.837%208.797%2C3.438%205.445%2C6.778%202.14%2C3.497%201.8%2C3.837%22%20style%3D%22fill%3A' + 'rgb(' + red($color-arrow) + '%2C' + green($color-arrow) + '%2C' + blue($color-arrow) + ')' + '%3B%22%20%2F%3E%3C%2Fsvg%3E');
  background-repeat: repeat-y, no-repeat;
  background-position: right 30px top 0, right 8px top 8px;
  background-size: 100px auto, 16px 16px;
}

@mixin select-background-position($right-1, $top-1, $right-2, $top-2) {
  background-position: right $right-1 top $top-1, right $right-2 top $top-2;
}

@mixin select-background-size($size) {
  /**
   * 1. On Safari a smaller background-size doesn't show the background-image on wide elements
   * 2. Size of the Arrow
   */
  background-size: 100px auto, /* 1 */
  $size $size; /* 2 */
}

/* Select */
select {
  &.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding-right: 42px;
    @include select-background($input-border-color, $color-hr-anthracite);
    @include select-background-position(30px, 0, 8px, 8px);
    @include select-background-size(16px);
    &:focus {
      @include select-background($input-border-focus, $input-border-focus);
    }
    &.form-control-sm {
      line-height: 15px;
      @include select-background-position(26px, 0, 7px, 7px);
      @include select-background-size(14px);
    }
    &.form-control-lg {
      line-height: 25px;
      @include select-background-position(41px, 0, 10px, 10px);
      @include select-background-size(22px);
    }
    /* Remove Select arrow on IE */
    &::-ms-expand {
      display: none;
    }
    &:hover {
      //padding: ($padding-base-vertical - 1) 41px ($padding-base-vertical - 1) ($padding-base-horizontal - 1);
      @include select-background-position(29px, 0, 7px, 7px);
      &.form-control-sm {
        //padding: ($padding-small-vertical - 1) 41px ($padding-small-vertical - 1) ($padding-small-horizontal - 1);
        @include select-background-position(25px, 0, 6px, 6px);
      }
      &.form-control-lg {
        //padding: ($padding-large-vertical - 1) 41px ($padding-large-vertical - 1) ($padding-large-horizontal - 1);
        @include select-background-position(40px, 0, 9px, 9px);
      }
    }
    &[disabled],
    &[readonly],
    fieldset[disabled] & {
      border-color: $input-border-disabled;
      background-image: none;
      cursor: default;
      color: $color-hr-anthracite;
      &:hover {
        border-width: 1px;
        @include select-background-position(30px, 0, 8px, 8px);
        &.form-control-sm {
          //padding: $padding-small-vertical $padding-small-horizontal;
          @include select-background-position(26px, 0, 7px, 7px);
        }
        &.form-control-lg {
          //padding: $padding-large-vertical  $padding-large-horizontal;
          @include select-background-position(41px, 0, 10px, 10px);
        }
      }
    }
  }
}

.custom-control-input {
  display: block !important;
  &:checked ~ .custom-control-label::before, &:checked ~ .custom-control-label-sm::before {
    color: $custom-control-indicator-checked-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    @include box-shadow($custom-control-indicator-checked-box-shadow);
  }

  &:focus ~ .custom-control-label::before {
    // the mixin is not used here to make sure there is feedback
    box-shadow: none;
    border-color: $color-hr-cyan;
  }

  &:active ~ .custom-control-label::before, &:active ~ .custom-control-label-sm::before {
    box-shadow:none;
  }

  &:disabled {
    ~ .custom-control-label-sm, ~ .custom-control-label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
        color: initial;
        border-color: $color-hr-grey;
      }
    }
  }
}

.custom-control-label {
  &::before {
    border: 1px solid;
    background: none;
  }
  &.single-checkbox {
    &::before, &::after {
      left: inherit;
    }
  }
}

.custom-control-label-sm {
  margin-bottom: 0;

  // Background-color and (when enabled) gradient
  &::before {
    border: 1px solid;
    background: none;
    position: absolute;
    top: (($line-height-base - $custom-control-indicator-size-sm) / 3);
    left: 0;
    display: block;
    width: $custom-control-indicator-size-sm;
    height: $custom-control-indicator-size-sm;
    pointer-events: none;
    content: "";
    user-select: none;
    @include box-shadow($custom-control-indicator-box-shadow);
  }

  // Foreground (icon)
  &::after {
    position: absolute;
    top: (($line-height-base - $custom-control-indicator-size-sm) / 3);
    left: 0;
    display: block;
    width: $custom-control-indicator-size-sm;
    height: $custom-control-indicator-size-sm;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $custom-control-indicator-bg-size;
  }
}

.custom-checkbox {
  .custom-control-label-sm::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label-sm {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }
    &::after {
      background-image: $custom-checkbox-indicator-icon-checked;
    }
  }

  .custom-control-input:indeterminate ~ .custom-control-label-sm {
    &::before {
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
      @include box-shadow($custom-checkbox-indicator-indeterminate-box-shadow);
    }
    &::after {
      background-image: $custom-checkbox-indicator-icon-indeterminate;
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label-sm::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
    &:indeterminate ~ .custom-control-label-sm::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

// Radios
//
// Tweak just a few things for radios.

.custom-radio {
  .custom-control-label-sm{
    margin-top:0.1rem;
  &::before {
    border-radius: $custom-radio-indicator-border-radius;
  }}

  .custom-control-input:checked ~ .custom-control-label-sm {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }
    &::after {
      background-image: $custom-radio-indicator-icon-checked;
    }
  }

  .custom-control-input:disabled {
    &:checked ~ .custom-control-label-sm::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

.custom-control-input {
  display: none;
}

.form-styled {
  .checkbox,
  .radio {
    > label {
      padding-left: 0;
    }
  }

  /* Radio Button and Checkbox */
  input[type="checkbox"],
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    & + span {
      position: relative;
      color: $color-hr-anthracite;
      min-height: 16px;
      height: 16px;
      width: 16px;
      display: inline-block;
      padding: 0;
      top: -2px;
      background: #fff;
      border: 1px solid $input-border-color;
      cursor: pointer;
      vertical-align: middle;
      margin-right: 8px;
    }
    &:checked + span:before {
      position: absolute;
      display: block;
      font-size: 12px;
    }
    &:disabled + span {
      border-color: $input-border-disabled;
      cursor: not-allowed;
      &:before {
        color: $input-border-disabled;
      }
      &:hover {
        border-width: 1px;
      }
    }
  }

  /* Checkbox */
  input[type="checkbox"] {
    & + span {
      border-radius: 0;
    }
    &:checked + span:before {
      left: 2px;
      top: -2px;
      display: block;
      content: '\2714';
      min-height: 16px;
      height: 16px;
      width: 16px;
    }
    & + span:hover,
    &:focus + span {
      border-width: 2px;
    }
    &:checked + span:hover,
    &:focus + span {
      &:before {
        left: 1px;
        top: -3px;
      }
    }
  }

  /* Radio Button */
  input[type="radio"] {
    & + span {
      border-radius: 50%;
    }
    &:checked + span:before {
      left: 3px;
      top: 3px;
      content: '';
      background: $color-hr-anthracite;
      min-height: 8px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
    &:disabled + span:hover:before {
      left: 3px;
      top: 3px;
    }
    & + span:hover,
    &:focus + span {
      border-width: 2px;
      &:before {
        left: 2px;
        top: 2px;
      }
    }
  }
}

//fix for ui select component
.input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
  border-radius: 0;
}

.form-control-xs {
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.col-form-label-xs {
  padding-top: 0;
  padding-bottom: 0;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}

select.form-control-xs {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}
