// Headings
// -------------------------

h1, .h1 {
  color: $color-hr-cyan;
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size-screen-sm;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size-screen-lg;
  }
}

h2, .h2 {
  color: $color-hr-cyan;
  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size-screen-sm;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h2-font-size-screen-lg;
  }
}

h3, .h3 {
  color: $color-hr-blue;
  @include media-breakpoint-up(sm) {
    font-size: $h3-font-size-screen-sm;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size-screen-lg;
  }
}

h4, .h4,
h5, .h5,
h6, .h6 {
  color: $color-hr-anthracite;
  @include media-breakpoint-up(sm) {
    font-size: $h4-font-size-screen-sm;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size-screen-lg;
  }
}

// Links in paragraphs have a leading > symbol
p a,
a.angle-before,
a.link {
  padding-left: 6px;

  &:before {
    content: '';
    border: solid darken($link-color, 10%);
    // I made it 10% darker to make it appear in the same color.
    // michael@ziem.io
    border-width: 0 1px 1px 0;
    display: inline-block;
    width: 6px;
    height: 6px;
    position: relative;
    top: -2px;
    left: -4px;
    transform : rotate(-45deg);
  }
}

p {
  //margin: 0 0 $line-height-computed 0;
}

.lead {
  //margin-bottom: $line-height-computed;
  font-size: $font-size-base;
  color: $color-hr-blue;
  font-weight: normal;
  line-height: $line-height-base;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-base;
  }
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
  font-size: $font-size-sm;
}

// Lists
// -------------------------

// Unordered and Ordered lists
ul,
ol {
  //padding-left: 26px;

  li {
    line-height: 1.5;
  }
  ul,
  ol {
    margin-bottom: 0;
  }
}

// Emphasis & misc
// -------------------------

mark,
.mark {
  background-color: $color-hr-grey-blue;
  color: #ffffff;
  padding: 0 .2em;
}

.bg-primary,
.bg-info,
.bg-success,
.bg-warning,
.bg-danger {
  padding: 0 6px;
  color: #fff;
}


pre {
  background-color: $color-hr-grey-light;
}

//hr{
//border-top: none;
//}