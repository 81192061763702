//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  font-size: $breadcrumb-font-size;
  > .breadcrumb-item {
    > a {
      color: $breadcrumb-color;
    }
    + .breadcrumb-item:before {
      color: $breadcrumb-separator-color;
    }
  }
}
