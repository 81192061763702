/**
 * Spinner
 */

//== Spinners
//
//##
$spinner-sm-size: 30px !default;
$spinner-md-size: 60px !default;
$spinner-lg-size: 90px !default;
$spinner-speed: 1s !default;
//$spinner-fallback-url: "../../dist/images/ajax-loader.gif" !default;
$spinner-fallback-url: "images/ajax-loader.gif" !default;

$spinner-zindex: 1080;

@-webkit-keyframes spinner-rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinner-rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border: solid $color-hr-grey-medium;
  border-right-color: $color-hr-blue;
  border-radius: 50%;
  display: inline-block;

  animation-name : spinner-rotate;
  animation-duration:$spinner-speed;
  animation-iteration-count:infinite;
  animation-timing-function:linear;

  .lt-ie10 & {
    background: url($spinner-fallback-url) center center no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;
    animation-name : none;
  }

  &.spinner-sm {
    border-width: ($spinner-sm-size / 7);
    width: $spinner-sm-size;
    height: $spinner-sm-size;
  }
  &.spinner-md {
    border-width: ($spinner-md-size / 7);
    width: $spinner-md-size;
    height: $spinner-md-size;
  }
  &.spinner-lg {
    border-width: ($spinner-lg-size / 7);
    width: $spinner-lg-size;
    height: $spinner-lg-size;
  }
  &.spinner-inline {
    $loading-spinner-size: $font-size-base;
    border-width: ($loading-spinner-size / 7);
    width: $loading-spinner-size;
    height: $loading-spinner-size;
  }
}

.spinner-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  z-index: $spinner-zindex;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: 27px;
  }

  .spinner {
    position: relative;
    top: 50%;
    &.spinner-sm {
      margin-top: -$spinner-sm-size/2;
    }
    &.spinner-md {
      margin-top: -$spinner-md-size/2;
    }
    &.spinner-lg {
      margin-top: -$spinner-lg-size/2;
    }
  }
}
