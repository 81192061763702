.card-header {
  color: #fff;
}
.card-footer {
  border-top: 1px solid #ddd;
  background-color: $color-hr-grey-light;
}

.card{
  > .card-body + .table,
  > .card-body + .table-responsive,
  > .table + .card-body,
  > .table-responsive + .card-body,
  > .card-header + .table,
  > .card-header + .table-responsive,
  > .table + .card-header,
  > .table-responsive + .card-header {
    border-bottom: 0;
  }
  @include box-shadow(none !important);

  &.card-primary{
    border: 1px solid $primary;
    > .card-header {
      background-color: $primary;
      border-bottom-color: $primary;
    }
  }
  &.card-success{
    border: 1px solid $info;
    > .card-header {
      background-color: $success;
      border-bottom-color: $info;
    }
  }
  &.card-info{
    border: 1px solid $info;
    > .card-header {
      background-color: $info;
      border-bottom-color: $info;
    }
  }
  &.card-warning{
    border: 1px solid $info;
    > .card-header {
      background-color: $warning;
      border-bottom-color: $info;
    }
  }
  &.card-danger{
    border: 1px solid $info;
    > .card-header {
      background-color: $danger;
      border-bottom-color: $info;
    }
  }
}