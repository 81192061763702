header.fixed-top{
  margin: 0 2px;
}

.nav-row{
  display: flex;
  flex-wrap: wrap;
}
.brand-row{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  a{
    font-size: 1rem;
    color: $color-hr-blue !important;
  }
  .brand-logo{
    margin: 5px 0;
  }
}
.app-header{
  display: block;
  padding: 5px 15px;
  background: $primary;
  color: #fff;
  .hr-logo{
    height: 14px;
    margin-top: -6px;
  }
  .pipe{
    margin: 0 5px;
  }

  .app-name{
    font-weight: bold;
  }
  @include clearfix; // clear it in case folks use .pull-right classes inside

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.header,
  // Legacy
.brand-header {
  position: relative;
  min-height: 75px;

  // Logo e.g. 'ReFlex'
  .subbrand-logo {
    display: block;
    float: left;
    height: 23px;
    margin: 33px 20px 20px 0;
    z-index: 1;
  }

  .subbrand-logo-large {
    display: block;
    float: left;
    height: 48px;
    margin: 20px 20px 20px 0;
    z-index: 1;
  }

  // Logo 'Hannover Re'
  .brand-logo {
    display: block;
    float: left;
    margin: 33px 20px 20px 0;
    height: 23px;
  }

  &.header-subbrand {
    // 1: Logo not displayed in small view
    .brand-logo {
      display: none; /* 1 */
      float: right;
      margin-right: 0;
      @include media-breakpoint-up(xs) {
        display: block;
      }
    }
  }

  // Name of Application e.g. 'Management Information & Analytics'
  .brand-application-name,
  .application-name {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    color: $color-hr-cyan;
    margin: 0 0 20px 0;
    @include media-breakpoint-up(xs) {
      position: absolute;
      width: auto;
      bottom: 14px;
      left: 200px;
      right: 200px;
      margin: 0;
      text-align: center;
    }
  }

  &.header-subbrand {
    .brand-application-name,
    .application-name {
      color: $color-hr-anthracite;
    }
  }
}

