//
// Button groups
// --------------------------------------------------


// Carets in other button sizes
.btn-lg .caret,
  // Upside down carets for .dropup
.dropup .btn-lg .caret {
  margin-top: -7px;
  border-style: solid;
  border-width: 0 1px 1px 0;
}

.btn-group{
  .dropdown-menu{
    > .dropdown-item {
      border-top: 1px solid $color-hr-blue;
      &:first-child {
        border-top: none;
      }
    }
  }
}

.btn-group-xs > .btn { @extend .btn-xs; }