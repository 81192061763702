//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  > .page-item {
    > .page-link {
      border-top: none;
      border-bottom: none;
    }
    &:first-child {
      > .page-link {
        border-left: none;
      }
    }
    &:last-child {
      > .page-link {
        border-right: none;
      }
    }
  }
}
