.navbar-default,
.navbar-primary,
.navbar-inverse,
  /* Legacy */
.navbar-info {
  width: 100%;
  padding: 0;
  background-color: $color-hr-blue;
  border: 1px solid $color-hr-blue;
  color: #fff;

  .navbar-brand{
    padding: 0px 15px;
    margin: 0;
  }

  a,
  a.navbar-brand,
  .navbar-nav > li > a {
    color: #fff;
    &:focus {
      color: #fff;
    }
  }

  a.navbar-brand:active,
  //.navbar-toggler:hover,
  //.navbar-toggler:not(.collapsed),
  .navbar-nav .active > .nav-link,
  .navbar-nav .nav-link:not([disabled]):not(.disabled).nav-link.active,
  .navbar-nav .nav-link:not([disabled]):not(.disabled):hover,
  .navbar-nav .nav-link:not([disabled]):not(.disabled):focus
  {
    background-color: #fff;
    color: $color-hr-anthracite;
  }

  .dropdown-menu{
    background-color: $color-hr-blue;
    border: 1px solid $color-hr-blue;
    color: #fff;

    .dropdown-item{
      &:hover,
      &:focus,
      &.active{
      background-color: #fff;
      color: $color-hr-anthracite;
    }
    }
  }

  ul.dropdown-menu,
  .dropdown-menu
  {
    background-color: $color-hr-blue;
    border: 1px solid $color-hr-blue;
    color: #fff;
    a {
      color: #fff;
    }
    > .active {
      a {
        color: $color-hr-anthracite;
      }
    }
  }

  .navbar-toggle {
      padding: 8px 10px;
  }
  .navbar-toggler-icon {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
  }
}
