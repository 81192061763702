// main
$color-hr-blue:               #005192;
$color-hr-cyan:               #009ee0;
$color-hr-grey:               #91877b;

$color-hr-anthracite:         #3e3e3e;
$color-hr-grey-blue:          #a0b4bf;

// background
$color-hr-grey-medium:        #eae8e5;
$color-hr-grey-light:         #f5f3f2;
$color-hr-grey-blue-medium:   #e2e8ec;
$color-hr-grey-blue-light:    #f0f3f5;

// additional
$color-hr-green-light:        #acd819;
$color-hr-green-dark:         #7a9501;

$color-hr-orange-light:       #e9ad05;
$color-hr-orange-dark:        #cc6c08;

$color-hr-light-blue:         #83d0f0;
$color-hr-dark-grey:          #796e6b;

$color-hr-red:                #e60000;

// states
$color-hr-danger:             #FF0000;
$color-hr-warning:            #FFD200;
$color-hr-success:            #ACD819;

$color-hr-disabled-border:    $color-hr-grey-blue-medium;
$color-hr-disabled-text:      $color-hr-grey-blue-medium;